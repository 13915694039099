import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { FILTRAR_PARAMETROS, LISTAR_PARAMETROS, GERAR_RELATORIO_PARAMETROS } from './constants';

import { 
  gerarRelatorioParametrosErro,
  gerarRelatorioParametrosSucesso,
  obterParametrosSucesso, 
  obterParametrosErro } from './actions';
import { obterParametros } from '../requests';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosParametros() {
  try {
    const filtro = yield select((state) => state.Parametros.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Parametros]);
    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'id',
        ordem: preferencias?.ordem?.toUpperCase() || 'DESC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterParametros, query);
    yield put(obterParametrosSucesso(data));
  } catch (error) {
    console.error(error);
    yield put(obterParametrosErro(error.response?.data?.message || error.message));
  }
}

function* relatorioParametros() {
  const { filtros, relatorio } = yield select((state) => state.Parametros);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Parametros]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.Parametros, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioParametrosSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioParametrosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(
      gerarRelatorioParametrosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'),
    );
  }
}


function* ordenarParametros({ payload }) {
  if (payload.programaId === ProgramaCodigo.Parametros) {
    yield call(listarTodosParametros);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Parametros) {
    yield call(listarTodosParametros);
  }
}

export function* watchListarParametros() {
  yield takeLatest(LISTAR_PARAMETROS, listarTodosParametros);
}

export function* watchFiltrarParametros() {
  yield takeLatest(FILTRAR_PARAMETROS, listarTodosParametros);
}

export function* watchRelatorioParametros() {
  yield takeLatest(GERAR_RELATORIO_PARAMETROS, relatorioParametros);
}


export function* watchOrdenarParametros() {
  yield takeLatest(SET_TABLE_ORDER, ordenarParametros);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* parametrosaga() {
  yield all([
    fork(watchListarParametros),
    fork(watchFiltrarParametros),
    fork(watchRelatorioParametros),
    fork(watchOrdenarParametros),
    fork(watchLimitePaginacao),
  ]);
}

export default parametrosaga;

import {
  FILTRAR_NOTAS_ENTRADAS,
  GERAR_RELATORIO_NOTAS_ENTRADAS,
  GERAR_RELATORIO_NOTAS_ENTRADAS_ERRO,
  GERAR_RELATORIO_NOTAS_ENTRADAS_SUCESSO,
  LISTAR_NOTAS_ENTRADAS,
  LISTAR_NOTAS_ENTRADAS_ERRO,
  LISTAR_NOTAS_ENTRADAS_SUCESSO,
} from './constants';

export const obterTodosNotasEntradas = () => ({
  type: LISTAR_NOTAS_ENTRADAS,
});

export const obterNotasEntradasSucesso = (notasEntradas) => ({
  type: LISTAR_NOTAS_ENTRADAS_SUCESSO,
  payload: notasEntradas,
});

export const obterNotasEntradasErro = (mensagem) => ({
  type: LISTAR_NOTAS_ENTRADAS_ERRO,
  payload: mensagem,
});

export const filtrarNotasEntradas = (filtros) => ({
  type: FILTRAR_NOTAS_ENTRADAS,
  payload: filtros,
});
export const gerarRelatorioNotasEntradas = (payload) => ({
  type: GERAR_RELATORIO_NOTAS_ENTRADAS,
  payload: payload,
});

export const gerarRelatorioNotasEntradasSucesso = (alert) => ({
  type: GERAR_RELATORIO_NOTAS_ENTRADAS_SUCESSO,
  payload: alert,
});

export const gerarRelatorioNotasEntradasErro = (mensagem) => ({
  type: GERAR_RELATORIO_NOTAS_ENTRADAS_ERRO,
  payload: mensagem,
});

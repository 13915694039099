import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { FILTRAR_MODELOS_ETIQUETA, GERAR_RELATORIO_MODELOS_ETIQUETA, LISTAR_MODELOS_ETIQUETA } from './constants';
import { obterModelosEtiquetasAsync } from '../requests';
import { gerarRelatorioModelosEtiquetasErro, gerarRelatorioModelosEtiquetasSucesso, obterModelosEtiquetasErro, obterModelosEtiquetasSucesso } from './actions';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorio } from '../../../services/requests/relatorio';
import { TipoRelatorio } from '../../../constants/enums/impressao.enum';

function* listarTodosModelosEtiquetas() {
  try {
    const filtro = yield select((state) => state.ModelosEtiquetas.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ModelosEtiquetas]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterModelosEtiquetasAsync, query);
    yield put(obterModelosEtiquetasSucesso(data));
  } catch (error) {
    yield put(obterModelosEtiquetasErro(error.response?.data?.message || error.message));
  }
}

function* relatorioModelosEtiquetas() {
  const { filtros, relatorio } = yield select((state) => state.ModelosEtiquetas);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.ModelosEtiquetas]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data } = yield call(gerarRelatorio, ProgramaCodigo.ModelosEtiquetas, query, tipo);

    if (tipo === TipoRelatorio.visualizar) {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioModelosEtiquetasSucesso());
        window.open(url, '_blank');
      }
    } else {
      yield put(
        gerarRelatorioModelosEtiquetasSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    }
  } catch (e) {
    yield put(gerarRelatorioModelosEtiquetasErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

function* ordenarModelosEtiquetas({ payload }) {
  if (payload.programaId === ProgramaCodigo.ModelosEtiquetas) {
    yield call(listarTodosModelosEtiquetas);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.ModelosEtiquetas) {
    yield call(listarTodosModelosEtiquetas);
  }
}

export function* watchListarModelosEtiquetas() {
  yield takeLatest(LISTAR_MODELOS_ETIQUETA, listarTodosModelosEtiquetas);
}

export function* watchFiltrarModelosEtiquetas() {
  yield takeLatest(FILTRAR_MODELOS_ETIQUETA, listarTodosModelosEtiquetas);
}

export function* watchRelatorioModelosEtiquetas() {
  yield takeLatest(GERAR_RELATORIO_MODELOS_ETIQUETA, relatorioModelosEtiquetas);
}

export function* watchOrdenarModelosEtiquetas() {
  yield takeLatest(SET_TABLE_ORDER, ordenarModelosEtiquetas);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* modelosEtiquetasSaga() {
  yield all([
    fork(watchListarModelosEtiquetas),
    fork(watchFiltrarModelosEtiquetas),
    fork(watchRelatorioModelosEtiquetas),
    fork(watchOrdenarModelosEtiquetas),
    fork(watchLimitePaginacao),
  ]);
}

export default modelosEtiquetasSaga;

import {
  FILTRAR_MODELOS_RELATORIO,
  GERAR_RELATORIO_MODELOS_RELATORIO,
  GERAR_RELATORIO_MODELOS_RELATORIO_ERRO,
  GERAR_RELATORIO_MODELOS_RELATORIO_SUCESSO,
  LISTAR_MODELOS_RELATORIO,
  LISTAR_MODELOS_RELATORIO_ERRO,
  LISTAR_MODELOS_RELATORIO_SUCESSO,
} from './constants';

export const obterTodosModelosRelatorios = () => ({
  type: LISTAR_MODELOS_RELATORIO,
});

export const obterModelosRelatoriosSucesso = (ModelosRelatorios) => ({
  type: LISTAR_MODELOS_RELATORIO_SUCESSO,
  payload: ModelosRelatorios,
});

export const obterModelosRelatoriosErro = (mensagem) => ({
  type: LISTAR_MODELOS_RELATORIO_ERRO,
  payload: mensagem,
});

export const filtrarModelosRelatorios = (filtros) => ({
  type: FILTRAR_MODELOS_RELATORIO,
  payload: filtros,
});

export const gerarRelatorioModelosRelatorios = (payload) => ({
  type: GERAR_RELATORIO_MODELOS_RELATORIO,
  payload: payload,
});

export const gerarRelatorioModelosRelatoriosSucesso = (alert) => ({
  type: GERAR_RELATORIO_MODELOS_RELATORIO_SUCESSO,
  payload: alert,
});

export const gerarRelatorioModelosRelatoriosErro = (mensagem) => ({
  type: GERAR_RELATORIO_MODELOS_RELATORIO_ERRO,
  payload: mensagem,
});

export enum TipoRelatorio {
  visualizar = 1,
  pdf = 2,
  csv = 3,
  xlsx = 4,
}

export enum ModeloRelatorio {
  listagem = 1,
}

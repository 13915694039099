import Swal from 'sweetalert2';
import { filtrosPadroes } from '../../../constants/filtros';
import {
  FILTRAR_ENTRADA_FRUTAS,
  GERAR_RELATORIO_ENTRADA_FRUTAS,
  GERAR_RELATORIO_ENTRADA_FRUTAS_ERRO,
  GERAR_RELATORIO_ENTRADA_FRUTAS_SUCESSO,
  LISTAR_ENTRADA_FRUTAS,
  LISTAR_ENTRADA_FRUTAS_ERRO,
  LISTAR_ENTRADA_FRUTAS_SUCESSO,
} from './constants';

const INIT_STATE = {
  itens: [],
  filtros: { ...filtrosPadroes },
  carregando: false,
  relatorio: {},
  gerandoRelatorio: false,
};

const EntradaFrutasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_ENTRADA_FRUTAS:
      return { ...state, carregando: true };
    case FILTRAR_ENTRADA_FRUTAS:
      return {
        ...state,
        filtros: action.payload,
        carregando: true,
      };
    case LISTAR_ENTRADA_FRUTAS_SUCESSO:
      return {
        ...state,
        itens: action.payload,
        carregando: false,
      };
    case LISTAR_ENTRADA_FRUTAS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar entrada de frutas',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, itens: [], carregando: false };
    case GERAR_RELATORIO_ENTRADA_FRUTAS:
      return {
        ...state,
        filtros: action.payload.filtros,
        relatorio: action.payload.relatorio,
        gerandoRelatorio: true,
      };
    case GERAR_RELATORIO_ENTRADA_FRUTAS_SUCESSO:
      if (action.payload) {
        Swal.fire({
          icon: 'success',
          title: action.payload.titulo,
          timer: action?.payload?.timer || 2000,
          text: action.payload.mensagem,
        });
      }
      return { ...state, gerandoRelatorio: false };
    case GERAR_RELATORIO_ENTRADA_FRUTAS_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Falha ao gerar Relatório',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, gerandoRelatorio: false };
    default:
      return state;
  }
};

export default EntradaFrutasReducer;

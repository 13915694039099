import {
  FILTRAR_MODELOS_ETIQUETA,
  GERAR_RELATORIO_MODELOS_ETIQUETA,
  GERAR_RELATORIO_MODELOS_ETIQUETA_ERRO,
  GERAR_RELATORIO_MODELOS_ETIQUETA_SUCESSO,
  LISTAR_MODELOS_ETIQUETA,
  LISTAR_MODELOS_ETIQUETA_ERRO,
  LISTAR_MODELOS_ETIQUETA_SUCESSO,
} from './constants';

export const obterTodosModelosEtiquetas = () => ({
  type: LISTAR_MODELOS_ETIQUETA,
});

export const obterModelosEtiquetasSucesso = (ModelosEtiquetas) => ({
  type: LISTAR_MODELOS_ETIQUETA_SUCESSO,
  payload: ModelosEtiquetas,
});

export const obterModelosEtiquetasErro = (mensagem) => ({
  type: LISTAR_MODELOS_ETIQUETA_ERRO,
  payload: mensagem,
});

export const filtrarModelosEtiquetas = (filtros) => ({
  type: FILTRAR_MODELOS_ETIQUETA,
  payload: filtros,
});

export const gerarRelatorioModelosEtiquetas = (payload) => ({
  type: GERAR_RELATORIO_MODELOS_ETIQUETA,
  payload: payload,
});

export const gerarRelatorioModelosEtiquetasSucesso = (alert) => ({
  type: GERAR_RELATORIO_MODELOS_ETIQUETA_SUCESSO,
  payload: alert,
});

export const gerarRelatorioModelosEtiquetasErro = (mensagem) => ({
  type: GERAR_RELATORIO_MODELOS_ETIQUETA_ERRO,
  payload: mensagem,
});
